export const recentPosts = [
  {
    "id": 16,
    "site": "swg",
    "title": "Blokaroka™ on PC",
    "category": "game",
    "subCategory": [
      "desktop",
      "relax",
      "widgit"
    ],
    "description": "An interactive widget, digital blocks, to bring fun to your Windows desktop. Open source and 100% free!",
    "fullText": "<p>An interactive widget, digital blocks, to bring fun to your Windows desktop. A digital set of blocks you can play with while you compute.</p><h3>Open source and 100% free!</h3><p>Blokaroka™ is an Open Source software project and we'd love to have you contribute no matter what your skill level. <a href='https://github.com/michaelplzno/Blokaroka'>The repository is right here.</a> We've already had contributions to the project and are looking to get other developers involved. If you want join up, or offer feedback, pet pics, or words of support, please reach out to the links above!</p><figure><img src=\"/assets/images/blokaroka_screenshot.png\" alt=\"Play With Blocks\" /><figcaption>Blocks Right on Your Desktop</figcaption></figure><p>The latest version is v0.2.02:</p><ul><li>Gravity toggle.</li><li>Gravity settings save to game file.</li><li>Blocks lock in place when attaching.</li><li>Physics blocks bloks from covering start menu.</li></ul><p>To run Blokaroka, unzip the file into a directory of your choosing and double click \"Blokaroka.exe\" If a warning pops up click MORE INFO and then click RUN ANYWAY. To close the program hit the escape key any time.</p>",
    "authorName": "Michael Silverman",
    "authorLink": "https://michaelplzno.com",
    "authorAvatar": "/assets/images/author.jpg",
    "createdAt": "January 7, 2025",
    "cover": "/assets/images/Blokaroka_Thumb.png",
    "ctaButtons": [
      {
        "platformLogo": "/assets/images/windows-logo.png",
        "titleText": "Get Blokaroka™ on PC",
        "priceTag": "FREE AND OPEN SOURCE!",
        "appIcon": "/assets/images/BlokarokaIcon.png",
        "linkTo": "/assets/downloads/Blokaroka-v0.2.02.zip"
      }
    ]
  },
  {
    "id": 25,
    "site": "cda",
    "title": "Week of Newspapers",
    "category": "doodle",
    "subCategory": [
      "coffee",
      "sketches",
      "newspaper"
    ],
    "description": "Wow! Our very own artist, Michael PLZNO Silverman, was featured in the Somerville Times. The trick to sounding good was that I was able to write my answers to the questions in an email instead of having to come up with them off the top of my head. Here is",
    "fullText": "<p>Wow! Our very own artist, <strong>Michael PLZNO Silverman</strong>, was featured in the Somerville Times. The trick to sounding good was that I was able to write my answers to the questions in an email instead of having to come up with them off the top of my head. <a href=\"https://dougholder.blogspot.com/2024/10/somerville-artist-michael-silverman.html\" target=\"_blank\">Here is the full text of the article.</a></p><p>But it is also another big week because our YouTube channel got to 500 subscribers which means we are now allowed to sell our soul to the Alphabet Corporation, Google, and give it all up to our corporate overlords! To celebrate, we made this video:</p><div class=\"responsive-iframe-container\"><iframe src=\"https://www.youtube.com/embed/1zZjukD4Gq0?rel=0\" allowfullscreen=\"allowfullscreen\" frameborder=\"0\"></iframe></div><p>And our Coffee Doodle Art Facebook group is right at 1,000 members... wow, stats stats, numbers numbers, very professional. Check out the group page on Facebook <a href=\"https://www.facebook.com/share/g/1J5zyskCtz/\" target=\"_blank\">here</a>.</p>",
    "authorName": "Lily and Michael",
    "authorAvatar": "/assets/images/lilymichael.jpg",
    "createdAt": "December 21, 2024",
    "cover": "/assets/images/CDA25-thumb.jpg",
    "images": [
      "/assets/cda/24.12.22/01.M.12.11.24%20Floating%20Cactus.JPG",
      "/assets/cda/24.12.22/02.L.12.10.24%20The%20Biggest%20Secret.JPG",
      "/assets/cda/24.12.22/03.M.12.14.24%20Bird%20and%20Bear.JPG",
      "/assets/cda/24.12.22/04.M.12.10.24%20El%20Musico.JPG",
      "/assets/cda/24.12.22/05.M.12.03.24%20Elon%20Musk.JPG",
      "/assets/cda/24.12.22/06.M.12.08.24%20Beso.jpg",
      "/assets/cda/24.12.22/07.L.12.01.24%20Lol%20Nope.JPG",
      "/assets/cda/24.12.22/08.M.12.01.24%20Mind%20Games.JPG",
      "/assets/cda/24.12.22/09.M.12.13.24%20Use%20Thy%20Staff.JPG"
    ],
    "duration": "9 pieces",
    "ctaButtons": [
      {
        "platformLogo": "/assets/images/cta-email.png",
        "titleText": "Email Us To Buy a Print",
        "priceTag": "$30 Per Print",
        "appIcon": "/assets/images/CDALogo.png",
        "linkTo": "mailto:coffeedoodleart@gmail.com?subject=I'd like to buy one of your prints!"
      }
    ]
  },
  {
    "id": 12,
    "site": "matchyverse",
    "title": "Enter King Zazz",
    "category": "character",
    "subCategory": [
      "game",
      "baddie",
      "antagoinst"
    ],
    "description": "Not everyone in the Matchyverse™ is sunshine and rainbows! Entering the main baddie for this colorful universe.",
    "fullText": "<p>Not everyone in the <a href='https://matchyverse.com'>Matchyverse™</a> is sunshine and rainbows! Entering the main baddie for this colorful universe.</p><p>King Zazz is just a little fireball but so much more. He lives in his lava lamp styled laboratory in the Zazz Dimension where he beams rays of light that confuse the citizens of the <a href='https://matchyverse.com'>Matchyverse™</a>. Inside the Zazz Dimension, King Zazz can change anything, remaking even the sky to suit his mood.</p><p>But King Zazz has a lot of emotions, changing colors depending on how he currently feels. And Zazz is not alone! He has the \"Bad Actors\" a troupe of sentient robots who obey King Zazz's commands, when they feel like it of course!</p><p>Using his beams, Zazz is the king of glitches, making computers hiccup and bend to his will across the <a href='https://matchyverse.com'>Matchyverse™</a>. Can you defeat him?</p>",
    "authorName": "Michael Silverman",
    "authorLink": "https://michaelplzno.com",
    "authorAvatar": "/assets/images/matchyverse-signature-michael.webp",
    "createdAt": "October 12, 2024",
    "cover": "/assets/images/ZazzJester.webp",
    "ctaButtons": [
      {
        "platformLogo": "/assets/images/discord.png",
        "titleText": "Join Our Discord Chat",
        "priceTag": "Learn More!",
        "appIcon": "/assets/images/MatchyOGIcon.png",
        "linkTo": "https://discord.silverwaregames.com"
      }
    ]
  },
  {
    "id": 7,
    "site": "plzno",
    "title": "Compsci Guy: 3 Body",
    "category": "youtube",
    "subCategory": [
      "math",
      "compsi",
      "netflix"
    ],
    "description": "Analasys of popular netflix show 3 body problem. We go in depth as to why it is such an interesting and chaotic paradox.",
    "fullText": "<a href=\"https://www.youtube.com/watch?v=BbPE4R6KG_A\"> Watch on youtube! </a>",
    "authorName": "michaelplzno",
    "authorAvatar": "/assets/images/plzno.jpg",
    "createdAt": "Jun 23, 2024",
    "cover": "/assets/images/3BodyThumb.gif",
    "link": "https://www.youtube.com/watch?v=BbPE4R6KG_A",
    "duration": "6min"
  }
];