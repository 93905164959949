/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import EmptyList from "../../components/common/EmptyList";
import BlogList from "../../components/Home/BlogList";
import Header from "../../components/Home/Header";
import BlogItem from "../../components/Home/BlogList/BlogItem";
import { recentPosts } from "../../config/recentPosts";
import { links } from "../../config/links";
import "./style.css";
import { Helmet } from "react-helmet";

const defaultHeaderInfo = {
  top: "Silverware Games, Inc.",
  title: "We Bring the Fun™",
  tagline1: "Daily serving of free games and art.",
  tagline2: "Be the first to try these undiscovered indie gems!",
};

const Home = () => {
  const { site } = useParams();

  const [headerInfo, setHeaderInfo] = useState(defaultHeaderInfo);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [headerLogo, setHeaderLogo] = useState(null);

  useEffect(() => {
    setLoading(true);

    async function fetchBlog() {
      try {
        const listModule = await import(`../../config/${site}/data`);
        const { headerInfo, blogList } = listModule;

        setHeaderLogo(null);
        if (site) {
          const link = links.find((link) => link.site === site);
          if (link) {
            setHeaderLogo(link.logo);
          }
        }

        setBlogs(blogList);
        if (headerInfo) {
          setHeaderInfo(headerInfo);
        }
      } catch (error) {
        setBlogs([]);
        setHeaderInfo(defaultHeaderInfo);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    }

    fetchBlog();
  }, [site]);

  useEffect(() => {
    document.title = headerInfo.title;
  }, [headerInfo]);

  return (
    <div>
      <Helmet>
        <title>{headerInfo.title}</title>
        <meta
          name="description"
          content={headerInfo.tagline1 + " " + headerInfo.tagline2}
        />
        <meta property="og:title" content={headerInfo.title} />
        <meta
          property="og:description"
          content={headerInfo.tagline1 + " " + headerInfo.tagline2}
        />
        <meta
          property="og:image"
          content={"/assets/images/thumb.jpg"}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={headerInfo.title} />
        <meta
          name="twitter:description"
          content={headerInfo.tagline1 + " " + headerInfo.tagline2}
        />
        <meta name="twitter:image" content={headerInfo.title} />
      </Helmet>
      {site ? (
        <>
          {loading ? null : !blogs.length ? (
            <EmptyList />
          ) : (
            <BlogList blogs={blogs} headerLogo={headerLogo} />
          )}
        </>
      ) : (
        <>
          <Header headerInfo={headerInfo} />
          <div className="home-main-page">
            <p className="main-spacer-bottom">
              Welcome to Silverware Games, an indie game dev company: we serve
              up games, art, social media, and we cook it all on XBOX, PC, iOS,
              Android, and more.
            </p>
            <p className="main-spacer">
              Chow down on a listing of all our work from the last decade right
              up to today!
            </p>
          </div>
          <h2 className="spoonful-header">Spoonfull of Recent Blogs:</h2>
          <div className="recent-posts-grid">
            {recentPosts.map((post, index) => {
              const isNewest = index === 0; // Assuming the first post in recentPosts is the newest
              const link = links.find((link) => link.site === post.site);
              return (
                <>
                  <div key={post.id} className="blog-item-wrapper">
                    {isNewest && (
                      <img
                        src="assets/images/new.png"
                        alt="New"
                        className="new-badge"
                      />
                    )}
                    <BlogItem blog={post} index={index} />
                  </div>

                  <a key={post.id} href={link.url} className="blog-link-button">
                    <img
                      src={link.logo}
                      alt={`${link.title} logo`}
                      className="blog-link-logo"
                    />
                    {link.title}
                  </a>
                </>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
